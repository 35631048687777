
























































































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import MembershipInformationService from '@/services/MembershipInformationService'
import { Member } from '@/models/Member'
import { namespace } from 'vuex-class'
const ChangeProfile = namespace('ChangeProfile')

@Component({
  components: {
    LayoutCompact
  }
})
export default class ConfirmMembershipInformation extends Vue {
  public year: string = ''
  public month: string = ''
  public day: string = ''
  public gender: string = ''
  public message: boolean = false

  private member = new Member()

  @ChangeProfile.Action
  private CHANGE_PROFILE!: (data: any) => any

  created() {
    const infoMember = localStorage.getItem('member')
    this.member = JSON.parse(infoMember ? infoMember : '')
    if (this.member.birthday && this.member.birthday !== 'Invalid date') {
      let date = this.member.birthday.split('-')
      this.year = date[0]
      this.month = date[1]
      this.day = date[2]
    }

    if (this.member.gender) {
      if (this.member.gender.toString() === '0') {
        this.gender = '男性'
      } else if (this.member.gender.toString() === '1') {
        this.gender = '女性'
      }
    }
  }

  public handleUpdateProfile() {
    MembershipInformationService.updateProfile(this.member).then(
      (response: any) => {
        if (response.data) {
          let profileUser: any = JSON.parse(localStorage.getItem('user') || '')
          let member: any = JSON.parse(localStorage.getItem('member') || '')
          profileUser.user.profile.first_name = member.first_name
          profileUser.user.profile.address = member.address
          profileUser.user.profile.apartment_no = member.apartment_no
          profileUser.user.profile.address = member.address
          profileUser.user.profile.birthday = member.birthday
          profileUser.user.profile.city = member.city
          profileUser.user.profile.first_name_kana = member.first_name_kana
          profileUser.user.profile.last_name = member.last_name
          profileUser.user.profile.last_name_kana = member.last_name_kana
          profileUser.user.profile.phone_no = member.phone_no
          profileUser.user.profile.prefecture = member.prefecture
          profileUser.user.profile.zip_code = member.zip_code
          profileUser.user.name = member.first_name + member.last_name
          profileUser.user.name_kana =
            member.first_name_kana + member.last_name_kana
          profileUser.user.email = member.email

          localStorage.setItem('user', JSON.stringify(profileUser))
          this.CHANGE_PROFILE(profileUser)
          this.$router.push('member-select-store')
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status === 440) {
          this.message = true
        }
      }
    )
  }

  private backMembershipInformation() {
    this.$router.push({ name: 'membership-information' })
  }
}
